import React, { useState } from 'react';
import styled from 'styled-components/macro';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import Button from './Button';
import theme from './theme';
import Card from './Card';
import Divider from './Divider';
import Input from './Input';
import { AlertCircle } from 'react-feather';

const { breakpoints, colors, fonts, spacing } = theme;

const LoginStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100%;
  background-color: ${colors.lightestGreen};

  .signin-container {
    width: calc(100% - 1rem);
    max-width: 368px;

    .signin-form {
      padding: 2rem;

      .error-message {
        text-align: left;
        font-size: 0.875rem;
        font-weight: ${fonts.weight.regular};
        color: ${colors.roles.danger};
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        svg {
          fill: ${colors.roles.danger};
          stroke: ${colors.white};
          margin-right: 1.5rem;
        }

        div {
          flex: 1;
        }
      }

      p {
        margin: 0 0 2rem;
        font-weight: ${fonts.weight.lighter};
        line-height: 140%;
        color: ${colors.text.darkGray};
      }

      input {
        margin-bottom: 0.5rem;

        ::placeholder {
          font-weight: ${fonts.weight.light};
        }
      }

      button {
        margin-top: 1rem;
      }
    }

    h3 {
      font-size: ${fonts.size.s};
      font-weight: ${fonts.weight.medium};
      margin-block-end: 0;
      line-height: 1.4;
    }

    h1 {
      font-family: freight-display-pro;
      font-size: ${fonts.size.xxl};
      font-weight: ${fonts.weight.black};
      color: ${colors.green};
      line-height: 1.4;
      margin: 0 0 1rem;
      padding: 0 ${spacing.smd};
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    height: 100vh;
    padding-top: 3rem;
  }
`;

const ToastMessage = styled.div`
  color: green;
  font-weight: 700;
  padding: 2rem 1rem 1rem;
`;

const LoginForm = () => {
  const [response, setResponse] = useState(null);
  const [memberId, setMemberId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState(null);

  const fetchAuthToken = async (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_API_URL;
    setLoading(true);
    try {
      const response = await axios.post(url, {
        member_id: memberId,
        password: password,
      });

      if (response.status === 200) {
        setResponse(response.status);
      }
    } catch (e) {
      setError(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginStyles>
      <div className="signin-container">
        <Card padding="1rem 0 0">
          {!response && (
            <>
              <h3>Connect Life Steps to your</h3>
              <h1>Young Living Account</h1>
              <Divider />
              <form className="signin-form" onSubmit={fetchAuthToken}>
                <p style={{ marginBottom: '1rem' }}>
                  In order to create your Life Steps account, log in with your
                  Young Living credentials.
                </p>
                <p>
                  We'll establish a secure connection and get your account set
                  up.
                </p>
                {error && (
                  <div className="error-message">
                    <AlertCircle />
                    <div>{error}</div>
                  </div>
                )}
                <Input
                  withLabel
                  labelText="YL Member No."
                  fieldType="tel"
                  onChange={(e) => {
                    setMemberId(e.target.value);
                    if (!isEmpty(error)) {
                      setError(null);
                    }
                  }}
                  placeholder="Enter your YL Member Number."
                  value={memberId}
                />

                <Input
                  withLabel
                  labelText="YL Password"
                  fieldType="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (!isEmpty(error)) {
                      setError(null);
                    }
                  }}
                  placeholder="Enter your YL password."
                  value={password}
                />

                <Button type="submit" loading={loading}>
                  Create Account
                </Button>
              </form>
            </>
          )}
          {response === 200 && (
            <div style={{ padding: spacing.lg }}>
              <h1>Awesome.</h1>
              <h2>You're all set!</h2>
              <p>
                We're setting up your account and we'll be in touch shortly.
              </p>
              <p>You can safely close this window.</p>
            </div>
          )}
          {toastMsg && <ToastMessage>{toastMsg}</ToastMessage>}
        </Card>
      </div>
    </LoginStyles>
  );
};

export default LoginForm;
