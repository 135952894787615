import React from 'react';
import ReactDOM from 'react-dom';
import styled, { ThemeProvider } from 'styled-components/macro';

import './styles.css';
import LoginForm from './LoginForm';

import theme from './theme';
import lslogo from './ls-mark-wordmark.svg';
import moment from 'moment';
const { breakpoints, colors, fonts } = theme;

const AppWrapper = styled.div`
  font-family: 'sofia-pro';
  text-align: center;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 100vh;
  width: 100vw;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <Logo>
          <img src={lslogo} alt="Life Steps" />
        </Logo>
        <LoginForm />
        <Footer>
          <div className="box" />
          <div className="box">
            Copyright &copy; {moment().format('YYYY')} | All Rights Reserved
          </div>
          <div className="box">
            <div className="links">
              <a
                href="https://www.lifestepsapp.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
              <a
                href="https://www.lifestepsapp.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </Footer>
      </AppWrapper>
    </ThemeProvider>
  );
}

const Logo = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  background: ${colors.lightestGreen};

  img {
    max-width: 8.5rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 1.5rem 2rem;
  font-size: ${fonts.size.s};
  text-align: center;
  text-transform: uppercase;
  background-color: ${colors.black};
  color: ${colors.white};
  font-family: ${fonts.family.sans};
  justify-self: flex-end;

  .box {
    width: calc(100% / 3);
    .links {
      display: flex;
      justify-content: flex-end;

      a {
        margin: 0 1rem;
        color: ${colors.white};

        &:hover {
          text-decoration: underline;
          text-decoration-color: rgba(255, 255, 255, 0.7);
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    flex-direction: column;

    .box {
      width: 100%;
      .links {
        justify-content: center;
        padding-top: 1rem;
      }
    }
  }
`;

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
