import React from 'react';
import styled from 'styled-components';
import defaultTheme from './theme';
import LoadingIndicator from './LoadingIndicator';

const { fonts, colors, spacing } = defaultTheme;

// Main Action Button (Can have icon after text)
const ButtonStyles = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '100%'};
  padding: ${spacing.md};
  max-height: ${spacing.xxl};
  font-size: ${fonts.size.s};
  font-weight: ${fonts.weight.demi};
  letter-spacing: 0.6px;
  text-transform: uppercase;
  background: ${(props) => (props.danger ? colors.roles.danger : colors.green)};
  color: ${colors.white};
  border: none;
  border-radius: 4px;
  outline: 0;

  &.inverse {
    font-weight: ${fonts.weight.demi};
    background: ${colors.white};
    color: ${(props) => (props.danger ? colors.roles.danger : colors.green)};
    border: 1px solid
      ${(props) => (props.danger ? colors.roles.danger : colors.green)};
  }

  svg {
    margin-left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Button = ({ children, loading, ...rest }) => {
  return (
    <ButtonStyles {...rest}>
      {loading ? <LoadingIndicator /> : children}
    </ButtonStyles>
  );
};

export default Button;
