import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { X } from 'react-feather';
import defaultTheme from './theme';

const Input = ({
  className,
  fieldType,
  id,
  labelText,
  name,
  onBlur,
  onChange,
  onClickRemove,
  pattern,
  placeholder,
  showRemoveButton,
  subLabel,
  value,
  withLabel,
}) => (
  <InputStyles>
    {withLabel && <label htmlFor={id}>{labelText}</label>}
    {subLabel && <span className="sub-label">{subLabel}</span>}
    <FlexWrapper>
      <input
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        pattern={pattern}
        placeholder={placeholder}
        type={fieldType}
        value={value}
        className={className}
      />
      {showRemoveButton && (
        <RemoveButton onClick={onClickRemove}>
          <X />
        </RemoveButton>
      )}
    </FlexWrapper>
  </InputStyles>
);

Input.defaultProps = {
  className: '',
  id: null,
  labelText: null,
  name: null,
  onBlur: () => null,
  onClickRemove: () => null,
  placeholder: null,
  pattern: null,
  showRemoveButton: false,
  subLabel: null,
  value: '',
  withLabel: false,
};

Input.propTypes = {
  className: PropTypes.string,
  fieldType: PropTypes.string.isRequired,
  id: PropTypes.string,
  labelText: ({ withLabel, ...rest }, propName) => {
    if (
      (withLabel === true && rest[propName] === undefined) ||
      (withLabel === true && typeof rest[propName] !== 'string')
    ) {
      return new Error(
        '`withLabel` is true, but no labelText was provided. Please provide a label text.'
      );
    }

    return null;
  },
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  showRemoveButton: PropTypes.bool,
  subLabel: PropTypes.string,
  value: PropTypes.string,
  withLabel: PropTypes.bool,
};

export default Input;

// Styles
const { colors, fonts } = defaultTheme;

const InputStyles = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 100%;

  label {
    margin-top: ${(props) => (props.subLabel !== null ? '0.5rem' : null)};
    margin-bottom: ${(props) => (props.subLabel !== null ? '0.5rem' : null)};
    padding: 0;
    color: ${colors.text.darkGray};
    font-weight: ${fonts.weight.regular};
    font-size: ${fonts.size.xs};
    line-height: 1.4;
    text-transform: uppercase;
  }

  .sub-label {
    font-size: ${fonts.size.xs};
    color: ${colors.text.gray};
    font-weight: ${fonts.weight.regular};
    margin-bottom: 0.5rem;
  }

  input {
    padding: 1rem;
    font-size: ${fonts.size.s};
    font-weight: ${fonts.weight.light};
    line-height: 1;
    border: 1px solid ${colors.darkerDividerGray};
    border-radius: 4px;
    color: ${colors.black};
    flex: 1;

    &::placeholder {
      font-weight: ${fonts.weight.lighter};
      color: ${colors.text.gray};
    }

    &:focus {
      border: 1px solid ${colors.darkGray};
      outline: none;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :first-child {
    display: flex;
    flex: 1;
    width: 100%;
  }
`;

const RemoveButton = styled.button`
  width: auto;
  margin: 0.5rem 0 0;
  padding: 0.5rem;
  color: ${colors.roles.danger};
  background: none;
  border: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${colors.roles.danger};
    cursor: pointer;
    opacity: 0.7;
  }

  span {
    margin-right: 0.5rem;
  }
`;
