import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const LoadingIndicator = ({ dark, size }) => {
  return <LoadingIndicatorStyles dark={dark} size={size} />;
};

LoadingIndicator.defaultProps = {
  dark: false,
  size: '1.5rem',
};

LoadingIndicator.propTypes = {
  dark: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoadingIndicator;

const LoadingIndicatorStyles = styled.div`
  width: ${(props) => (props.size ? props.size : '1.5rem')};
  height: ${(props) => (props.size ? props.size : '1.5rem')};
  border-radius: 50%;
  border: 0.3rem solid
    ${(props) =>
      props.dark ? props.theme.colors.green : 'rgba(256, 256, 256, 0.3)'};
  border-top-color: #fff;
  animation: 1.5s spin infinite linear;
  margin: auto;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
